
<template>
    <teleport to="#choiceNavArea">
        <div class="choiceNavArea">
            <p class="steptitle">{{ steptitle }}</p>
            <p v-if="appStepNum === 2" class="steptitleNote">選択した苗に合わせたプランターを表示しています。</p>
            <ul id="stepCategory" v-if="catBoolean">
                <li v-bind:class="{ 'current': getAllItems }" v-on:click="resetCatFilter()"><span>全て</span></li>
                <li v-for="(cat, index) in catValue" :key="index" v-on:click="getCat(cat, index)"><span>{{ cat }}</span>
                </li>
            </ul>
        </div>
    </teleport>


    <div id="choices" v-if="filterBool">
        <!-- item カテゴリーによるフィルタ-->
        <div v-for="(item, index) in filteredItems" :key="index" class="item">
            <div class="img">
                    <span class="more" v-on:click="modalOpen(item.id)" v-if="item.slideItems && item.slideItems.length > 0">
                    </span>
                <img v-bind:src="item.img" v-bind:alt="altText(item.name)">
                    <img v-if="appStepNum === 1" v-bind:src="item.img02" v-bind:alt="altText(item.name)" class="hvimg">
                     <p v-if="appStepNum === 1" class="icn_zoom">クリックで画像が拡大されます。</p>
            </div>
            <div class="itemContent">
                <div class="title_area">
                    <p v-if="appStepNum === 2" class="subName">ベジトラグ</p>
                    <p class="name" v-html="item.name"></p>
                </div>

                <div class="description">
                    {{ item.description }}
                </div>
                <!-- <p v-if="appStepNum === 1" class="price">&yen;{{ new Intl.NumberFormat().format(item.price) }}</p> -->
                <p v-if="appStepNum === 2" class="description2">{{ item.description2 }}</p>
                <button class="btn" v-on:click="filteredSelect(item.id, item.img)">選択する</button>
            </div>
        </div>
        <div v-show="filteredItems.length === 0" class="noitem">このカテゴリの商品はありません。</div>
    </div>
    <div id="choices" v-else>
        <div class="itemWrap">
            <!-- item 全件表示-->
            <div v-for="(item, index) in currentItems" :key="index" class="item">
                <div class="img">
                    <span class="more" v-on:click="modalOpen(item.id)" v-if="item.slideItems && item.slideItems.length > 0">
                    </span>
                    <img v-bind:src="item.img" v-bind:alt="altText(item.name)">
                    <img v-if="appStepNum === 1" v-bind:src="item.img02" v-bind:alt="altText(item.name)" class="hvimg">
                     <p v-if="appStepNum === 1" class="icn_zoom">クリックで画像が拡大されます。</p>
                </div>
                <div class="itemContent">
                    <div class="title_area">
                        <p v-if="appStepNum === 2" class="subName">ベジトラグ</p>
                        <p class="name" v-html="item.name"></p>
                    </div>
                    <div class="description">
                        {{ item.description }}
                    </div>
                    <!-- <p v-if="appStepNum === 1" class="price">&yen;{{ new Intl.NumberFormat().format(item.price) }}</p> -->
                    <p v-if="appStepNum === 2" class="description2">{{ item.description2 }}</p>
                    <button class="btn" v-on:click="select(item.id, item.img)">選択する</button>
                </div>
            </div>
        </div>
    </div>
    <Modal v-bind:modalFlg="modalFlgRef" v-bind:modalSetItem="modalSetItemRef" v-on:modalClose=modalClose />
</template>
  
<script>

import { useAltText, useModal } from './composition/useComposition.js'
import Modal from './modal.vue'
export default {

    setup() {
        const { altText } = useAltText()
        const { modalFlgRef, modalSetItemRef, modalOpen, modalClose } = useModal()
        return { altText, modalFlgRef, modalSetItemRef, modalOpen, modalClose }
    },
    components: {
        Modal
    },
    data() {
        return {
            currentItems: [],

            filterBool: 0,
            filteredItems: [],
            getAllItems: 1,

            slideMoreFlg: false,

            nav1IsOn: false,
            nav2IsOn: false,
            nav3IsOn: false,
            nav4IsOn: false,
        }
    },
    computed: {
        /**
         * store
         */
        appStepNum: function () { return this.$store.state.stepNumber },
        maxStepNum: function () { return this.$store.state.maxStepNumber },
        data: function () { return this.$store.state.data },
        selectedScene: function () { return this.$store.state.scene },
        planterIdArray: function () {
            return this.$store.state.step2_planterId
        },
        useSceneFlg: function () {
            return this.$store.state.useSceneFlg
        },
        /**
         * computed
         */
        currentData: function () { return this.data['items'] },
        catBoolean: function () { return this.data['cat']['bool'] },
        catValue: function () { return this.data['cat']['value'] },

        appStepNum: function () { return this.$store.state.stepNumber },
        steptitle: function () {
            let text = "";
            switch (this.appStepNum) {
                case 1:
                    text = "まずは苗を選択してください。";
                    break;
                case 2:
                    text = "プランターの有無を選択してください。";
                    break;
                case 3:
                    text = "土の要不要を選択してください。";
                    break;
                case 4:
                    text = "確認画像";
                    break;
                default:
                    text = 'まずは苗を選択してください。'
            }
            return text;
        }
    },
    watch: {
        appStepNum: function () {
            this.appStepNum
            this.resetCatFilter()
        },
        appStepNum: function () {
            switch (this.appStepNum) {
                case 1:
                    this.nav1IsOn = true;
                    this.nav2IsOn = false;
                    this.nav3IsOn = false;
                    this.nav4IsOn = false;
                    return;
                case 2:
                    this.nav1IsOn = false;
                    this.nav2IsOn = true;
                    this.nav3IsOn = false;
                    this.nav4IsOn = false;
                    return;
                case 3:
                    this.nav1IsOn = false;
                    this.nav2IsOn = false;
                    this.nav3IsOn = true;
                    this.nav4IsOn = false;
                    return;
                case 4:
                    this.nav1IsOn = false;
                    this.nav2IsOn = false;
                    this.nav3IsOn = false;
                    this.nav4IsOn = true;
                    return;
                default:
                    return;
            }

        }
    },
    methods: {
        // step1はdataすべてを取得、step2はstep1に紐づいてるstep2を取得
        setCurrentItems: function () {
            if (this.appStepNum === 1) {
                if (this.useSceneFlg === true) {
                    let selectedSene = this.selectedScene;
                    const newArray = this.currentData.filter(function (val) {
                        const sceneArray = val.scene.map(value => value);
                        for (let index = 0; index < sceneArray.length; index++) {
                            if (selectedSene === sceneArray[index]) {
                                return true;
                            }
                        }

                    })
                    this.currentItems = newArray
                } else {
                    this.currentItems = this.currentData
                }

            }
            if (this.appStepNum === 2) {
                //step1苗に紐づいたplanterIdがobjectで返されるので、それを配列に変更
                const planterIdArray = this.planterIdArray.map(value => value);
                // step1苗に紐づいた新しいプランター配列を作成
                const newArray = this.currentData.filter(function (val) {
                    let step2EachId = val.id
                    for (let index = 0; index < planterIdArray.length; index++) {
                        // planterのidがstep1で選んだ苗に紐づいていたらtrueを返す
                        if (step2EachId === planterIdArray[index]) {
                            return true;
                        }
                    }
                })
                this.currentItems = newArray

            }
        },
        select: function (id, itemImg) {
            this.$store.dispatch('selectedItem', { itemId: id, itemImg: itemImg })
        },
        filteredSelect: function (id, itemImg) {
            /**
             * カテゴリフィルター内で選択したアイテムのIDの商品がをもとの配列の何番目か取得する
             */
            // let index = this.currentData.findIndex((element) => element.id === id);
            this.$store.dispatch('selectedItem', { itemId: id, itemImg: itemImg })
        },
        getCat: function (cat, index) {
            this.filterBool = 1;
            this.getAllItems = 0;

            let newArray = this.currentItems.filter(item => {
                return item.cat.includes(index)
            });
            if (newArray) {
                this.filteredItems = newArray
            } else {
                this.filteredItems = []
            }

            $("#stepCategory").find("li").removeClass("current");
            $("#stepCategory").find("li").eq(index + 1).addClass("current")

        },
        resetCatFilter: function () {
            $("#stepCategory").find("li:not(:eq(0))").removeClass("current");
            this.filterBool = 0;
            this.getAllItems = 1;
            this.filteredItems = []
        },


    },
    created: function () {
        this.setCurrentItems()
    },
    mounted: function () {
        // console.log(this.useSceneFlg)
    }
}
</script>