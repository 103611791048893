<template>
    <div id="confirm">
        <div class="l_container_small">
            <div class="title_area">
                <p class="text">あなたの選んだセットはこちらです。</p>
            </div>

            <div class="content_wrapper">
                <div class="img_area">
                    <div class="img_box" v-if="!this.confirmImg">
                        <p class="noImg">画像を準備中です。</p>
                    </div>
                    <div class="img_box" v-if="this.confirmImg">
                        <img v-bind:src="confirmImg" alt="" />
                    </div>
                </div>

                <div class="info">
                    <div class="detail">
                        <div class="choice_list">
                            <div class="choice_list_row">
                                <div class="choice_list_step">
                                    <span class="step_name">STEP1</span>
                                    <span class="step_title">苗</span>
                                </div>
                                <div class="choice_list_item">
                                    <div class="choice_list_item__img">
                                        <img v-bind:src="step1.img" alt="" />
                                    </div>
                                    <div class="choice_list_item__text">
                                        {{ altText(step1.name) }}
                                    </div>
                                </div>
                            </div>

                            <div class="choice_list_row">
                                <div class="choice_list_step">
                                    <span class="step_name">STEP2</span>
                                    <span class="step_title">プランター</span>
                                </div>
                                <div class="choice_list_item">
                                    <div class="choice_list_item__img">
                                        <img v-bind:src="step2.img" alt="" />
                                    </div>
                                    <div class="choice_list_item__text">
                                        {{ altText(step2.name) }}
                                    </div>
                                </div>
                            </div>

                            <div class="choice_list_row">
                                <div class="choice_list_step">
                                    <span class="step_name">STEP3</span>
                                    <span class="step_title">土・肥料</span>
                                </div>
                                <div class="choice_list_item">
                                    <div class="choice_list_item__img">
                                        <img v-bind:src="step3_option1.img" alt="" />
                                        <img v-bind:src="step3_option2.img" alt="" />
                                    </div>
                                    <div class="choice_list_item__text">
                                        <p>{{ altText(step3_option1.name) }}</p>
                                        <p>{{ altText(step3_option2.name) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <teleport to="#navigation">
                        <div class="link next">
                            <a v-bind:href="link">上記のセット商品ページへ</a>
                        </div>
                    </teleport>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useAltText } from "./composition/useComposition.js";
import dataStep1 from "./data/step1.js";
import dataStep2 from "./data/step2.js";
import dataStep3 from "./data/step3.js";
export default {
    setup() {
        const { altText } = useAltText();
        return { altText };
    },
    data() {
        return {
            link: "",
            confirmImg: "assets/img/step/no_img.jpg",
            step1: {},
            step2: {},
            step3_option1: {},
            step3_option2: {},
        };
    },
    computed: {
        /**
         * store
         */
        setAllItems: function () {
            return this.$store.getters.setAllItems;
        },
    },
    props: {},
    watch: {},
    methods: {
        setStep1: function () {
            let itemIndex = this.setAllItems["step1_index"];
            let data = {
                index: itemIndex,
                id: dataStep1.items[itemIndex].id,
                name: dataStep1.items[itemIndex].name,
                img: dataStep1.items[itemIndex].img,
            };
            this.step1 = data;
        },
        stepsetStep2: function () {
            let itemIndex = this.setAllItems["step2_index"];
            let data = {
                index: itemIndex,
                id: dataStep2.items[itemIndex].id,
                name: dataStep2.items[itemIndex].name,
                img: dataStep2.items[itemIndex].img,
            };
            this.step2 = data;
        },
        setStep3_option1: function () {
            let itemIndex = this.setAllItems["step3_index"];
            let data = {
                index: itemIndex,
                id: dataStep3.items[itemIndex].option.soil.id,
                name: dataStep3.items[itemIndex].option.soil.name,
                img: dataStep3.items[itemIndex].option.soil.img,
            };

            this.step3_option1 = data;
        },
        setStep3_option2: function () {
            let itemIndex = this.setAllItems["step3_index"];
            let data = {
                id: dataStep3.items[itemIndex].option.fertilizer.id,
                name: dataStep3.items[itemIndex].option.fertilizer.name,
                img: dataStep3.items[itemIndex].option.fertilizer.img,
            };

            this.step3_option2 = data;
        },

        setLink: function () {
            let step1 = this.setAllItems["step1_id"];
            let step2 = this.setAllItems["step2_id"];
            let step3 = this.setAllItems["step3_id"];
            let link, confirmImg;
            const imgFolder = "assets/img/step/confirm/";
            switch (true) {
                /*
                【仮】ベジトラグはじめてセット 季節のお花アソート 春 8ポット
                */
                case step1 === "n1" && step2 === "p1_s" && step3 === "o1":
                    link = "https://living-garden-store.jp/collections/%E3%83%99%E3%82%B8%E3%83%88%E3%83%A9%E3%82%B0%E3%81%AF%E3%81%98%E3%82%81%E3%81%A6%E3%82%BB%E3%83%83%E3%83%88/products/set-001";
                    confirmImg = imgFolder + "n1_p1_s_o1.jpg";
                    break;

                /*
                【仮】季節のお花アソート 春 8ポット
                */
                case step1 === "n1" && step2 === "p1_r" && step3 === "o1":
                    link = "https://living-garden-store.jp/collections/%E3%83%99%E3%82%B8%E3%83%88%E3%83%A9%E3%82%B0%E3%83%AA%E3%83%94%E3%83%BC%E3%82%BF%E3%83%BC%E3%82%BB%E3%83%83%E3%83%88/products/set-012";
                    confirmImg = imgFolder + "n1_p1_r_o1.jpg";
                    break;

                /*
                はじめる菜園セット 夏のお花アソート 16ポット
                */
                case step1 === "n2" && step2 === "p1_s" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/hif-sm-s";
                    confirmImg = imgFolder + "n2_p1_s_o1.jpg";
                    break;

                /*
                つづける菜園セット 夏のお花アソート 16ポット
                */
                case step1 === "n2" && step2 === "p1_r" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/hif-sm-c";
                    confirmImg = imgFolder + "n2_p1_r_o1.jpg";
                    break;

                /*
                はじめる菜園セット 秋のお花アソート 16ポット
                */
                case step1 === "n3" && step2 === "p1_s" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/hif-au-s?_pos=2&_sid=ffd325dcf&_ss=r";
                    confirmImg = imgFolder + "n3_p1_s_o1.jpg";
                    break;

                /*
                つづける菜園セット 秋のお花アソート 16ポット
                */
                case step1 === "n3" && step2 === "p1_r" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/set-014?_pos=1&_sid=ffd325dcf&_ss=r";
                    confirmImg = imgFolder + "n3_p1_r_o1.jpg";
                    break;

                /*
                【仮】ベジトラグはじめてセット 季節のお花アソート 冬 8ポット
                */
                case step1 === "n4" && step2 === "p1_s" && step3 === "o1":
                    link = "https://living-garden-store.jp/collections/%E3%83%99%E3%82%B8%E3%83%88%E3%83%A9%E3%82%B0%E3%81%AF%E3%81%98%E3%82%81%E3%81%A6%E3%82%BB%E3%83%83%E3%83%88/products/set-004";
                    confirmImg = imgFolder + "n4_p1_s_o1.jpg";
                    break;

                /*
                【仮】季節のお花アソート 冬 8ポット
                */
                case step1 === "n4" && step2 === "p1_r" && step3 === "o1":
                    link = "https://living-garden-store.jp/collections/%E3%83%99%E3%82%B8%E3%83%88%E3%83%A9%E3%82%B0%E3%83%AA%E3%83%94%E3%83%BC%E3%82%BF%E3%83%BC%E3%82%BB%E3%83%83%E3%83%88/products/set-015";
                    confirmImg = imgFolder + "n4_p1_r_o1.jpg";
                    break;

                /*
                はじめる菜園セット スティックセニョール・ジャーマンカモミール 2種 4ポット
                */
                case step1 === "n5" && step2 === "p1_s" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/pg-awvg2-s";
                    confirmImg = imgFolder + "n5_p1_s_o1.jpg";
                    break;

                /*
                つづける菜園セット 白菜・チャイブ 2種 4ポット
                */
                case step1 === "n5" && step2 === "p1_r" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/pg-awvg2-c";
                    confirmImg = imgFolder + "n5_p1_r_o1.jpg";
                    break;

                /*
                はじめる菜園セット ソラマメ・ボリジ白 2種 4ポット
                */
                case step1 === "n6" && step2 === "p1_s" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/pg-awvg1-s-1";
                    confirmImg = imgFolder + "n6_p1_s_o1.jpg";
                    break;

                /*
                つづける菜園セット ソラマメ・ボリジ白 2種 4ポット
                */
                case step1 === "n6" && step2 === "p1_r" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/pg-awvg1-c";
                    confirmImg = imgFolder + "n6_p1_r_o1.jpg";
                    break;

                /*
                はじめる菜園セット スティックセニョール・ジャーマンカモミール 2種 4ポット
                */
                case step1 === "n7" && step2 === "p1_s" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/pg-awvg3-s";
                    confirmImg = imgFolder + "n7_p1_s_o1.jpg";
                    break;

                /*
                つづける菜園セット スティックセニョール・ジャーマンカモミール 2種 4ポット
                */
                case step1 === "n7" && step2 === "p1_r" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/pg-awvg3-c";
                    confirmImg = imgFolder + "n7_p1_r_o1.jpg";
                    break;

                /*
                はじめる菜園セット リーフレタス・レモンタイム 2種 4ポット
                */
                case step1 === "n8" && step2 === "p1_s" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/pg-awvg1-s";
                    confirmImg = imgFolder + "n8_p1_s_o1.jpg";
                    break;

                /*
                つづける菜園セット リーフレタス・レモンタイム 2種 4ポット
                */
                case step1 === "n8" && step2 === "p1_r" && step3 === "o1":
                    link = "https://living-garden-store.jp/products/pg-ssvg4-c";
                    confirmImg = imgFolder + "n8_p1_r_o1.jpg";
                    break;

                /*
                はじめる菜園セット ハーブティーにおすすめハーブ 4ポット
                */
                case step1 === "n9" && step2 === "p2_s" && step3 === "o2":
                    link = "https://living-garden-store.jp/products/pg-te4-s";
                    confirmImg = imgFolder + "n9_p2_s_o2.jpg";
                    break;

                /*
                つづける菜園セット ハーブティーにおすすめハーブ 4ポット
                */
                case step1 === "n9" && step2 === "p2_r" && step3 === "o2":
                    link = "https://living-garden-store.jp/products/pg-te4-c";
                    confirmImg = imgFolder + "n9_p2_r_o2.jpg";
                    break;

                /*
                はじめる菜園セット 料理におすすめハーブ 4ポット
                */
                case step1 === "n10" && step2 === "p2_s" && step3 === "o2":
                    link = "https://living-garden-store.jp/products/pg-fo4-s";
                    confirmImg = imgFolder + "n9_p2_s_o2.jpg";
                    break;

                /*
                つづける菜園セット 料理におすすめハーブ 4ポット
                */
                case step1 === "n10" && step2 === "p2_r" && step3 === "o2":
                    link = "https://living-garden-store.jp/products/pg-fo4-c";
                    confirmImg = imgFolder + "n9_p2_r_o2.jpg";
                    break;

                /*
                はじめる菜園セット 生活に活用ハーブ 4ポット
                */
                case step1 === "n11" && step2 === "p2_s" && step3 === "o2":
                    link = "https://living-garden-store.jp/products/pg-lf4-s";
                    confirmImg = imgFolder + "n9_p2_s_o2.jpg";
                    break;

                /*
                つづける菜園セット 生活に活用ハーブ 4ポット
                */
                case step1 === "n11" && step2 === "p2_r" && step3 === "o2":
                    link = "https://living-garden-store.jp/products/pg-lf4-c";
                    confirmImg = imgFolder + "n9_p2_r_o2.jpg";
                    break;

                default:
                    link = "";
                    confirmImg = this.confirmImg;
            }
            this.link = link;
            this.confirmImg = confirmImg;
        },
    },
    created: function () {
        this.setStep1();
        this.stepsetStep2();
        this.setStep3_option1();
        this.setStep3_option2();
        this.setLink();
    },
};
</script>
