
<template>
    <teleport to="#choiceNavArea">
        <div class="choiceNavArea">
            <p class="steptitle">土と肥料の量をご確認ください。</p>
            <p class="steptext attention">土と肥料の数量はプランターの大きさによって自動で決まります。（必須選択）</p>
        </div>
    </teleport>

    <div id="itemOptions">
        <div id="choices">
            <div class="item">
                <!-- 土-->
                <div class="img">
                    <span class="more" v-on:click="modalOpen_option(step3_indexKey, 'option1')"
                        v-if="setData.option.soil.slideItems && setData.option.soil.slideItems.length > 0">
                        more
                    </span>
                    <img v-bind:src="setData.option.soil.img" v-bind:alt="altText(setData.option.soil.name)">
                </div>
                <div class="itemContent">
                    <div class="title_area">
                        <p class="name" v-html="setData.option.soil.name"></p>
                    </div>
                </div>
            </div>
            <div class="item">
                <!-- 肥料-->
                <div class="img">
                    <span class="more" v-on:click="modalOpen_option(step3_indexKey, 'option2')"
                        v-if="setData.option.fertilizer.slideItems && setData.option.fertilizer.slideItems.length > 0">
                        more
                    </span>
                    <img v-bind:src="setData.option.fertilizer.img"
                        v-bind:alt="altText(setData.option.fertilizer.name)">
                </div>
                <div class="itemContent">
                    <div class="title_area">
                        <p class="name" v-html="setData.option.fertilizer.name"></p>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <Modal v-bind:modalFlg="modalFlgRef" v-bind:modalSetItem="modalSetItemRef" v-on:modalClose=modalClose />
</template>
  
<script>
import Modal from './modal.vue'
import { useAltText, useModal } from './composition/useComposition.js'
export default {
    setup() {
        const { altText } = useAltText()
        const { modalFlgRef, modalSetItemRef, modalOpen_option, modalClose } = useModal()
        return { altText, modalFlgRef, modalSetItemRef, modalOpen_option, modalClose }
    },
    components: {
        Modal
    },
    data() {
        return {
            slideMoreFlg: false,
            modalFlg: false,
            modalSetItem: undefined,

        }
    },
    computed: {
        /**
         * store
         */
        appStepNum: function () { return this.$store.state.stepNumber },
        data: function () { return this.$store.state.data },
        step3_indexKey: function () {
            return this.$store.state.step3_indexKey
        },
        /**
         * computed
         */
        currentData: function () { return this.data['items'] },
        setData: function () {
            return this.currentData[this.step3_indexKey]
        },
    },

}
</script>