import dataStep1 from '../data/step1.js'
import dataStep2 from '../data/step2.js'
import dataStep3 from '../data/step3.js'

import commonVariables from '../common/variables.js'

const imgNotSelected = commonVariables.img_not_selected;
const storeCommon = {

    getters: {
        /**
         * itemConfirm.vueで使用中
         * @param {*} state 
         * @returns 
         */
        setAllItems(state) {
            let setItem = [];
            setItem['step1_id'] = state.step1Id
            setItem['step2_id'] = state.step2Id
            setItem['step3_id'] = state.step3Id

            setItem['step1_index'] = state.step1_indexKey
            setItem['step2_index'] = state.step2_indexKey
            setItem['step3_index'] = state.step3_indexKey

            setItem['scene'] = state.scene

            return setItem;
        },
    },
    mutations: {
        switchData(state) {
            let data;
            switch (state.stepNumber) {
                case 1:
                    data = dataStep1
                    break;
                case 2:
                    data = dataStep2
                    break;
                case 3:
                    data = dataStep3
                    break;
                case 4:
                    break;
                default:
                    data = dataStep1
                    break;

            }
            state.data = data
            if (state.stepNumber === 3) {
                state.step3_option1_img = dataStep3['items'][state.step3_indexKey].option.soil.img
                state.step3_option2_img = dataStep3['items'][state.step3_indexKey].option.fertilizer.img
            }
        },
        clickedStepNav(state, num) {
            state.stepNumber = num
        },
        nextStep(state) {
            state.stepNumber++
        },
        backStep(state) {
            state.stepNumber--
        },
        setScene(state, scene) {
            state.scene = scene;
            state.stepNumber = 1

        },
        setItem1(state, item) {
            let itemId = item.itemId;
            let itemImg = item.itemImg;

            state.step1Id = itemId;
            state.step1Img = itemImg;

            /**
             * step1苗に紐づいているプランターのidを取得して、storeにsetする
             */
            //step1のIDの商品がをstep1のdataの配列の何番目か取得してstoreにセット
            let step1Index = state.data['items'].findIndex((element) => element.id === item.itemId);
            state.step1_indexKey = step1Index

            //step1にひもづくplanteridを取得してstoreにセット
            let planterId = state.data['items'][step1Index].planter
            state.step2_planterId = planterId

        },
        setItem2(state, item) {
            let itemId = item.itemId;
            let itemImg = item.itemImg;
            state.step2Id = itemId
            state.step2Img = itemImg

            /**
             * step2プランターに紐づいているstep3のoptionのidを取得して、storeにsetする
             */
            //step2プランターの商品がをstep2のdataの配列の何番目か取得してstoreにセット
            let step2Index = state.data['items'].findIndex((element) => element.id === item.itemId);
            state.step2_indexKey = step2Index

            //step2にひもづくoptionidを取得してstoreにセット
            let step3Id = state.data['items'][step2Index].option
            state.step3_optionId = step3Id

            /**
             * step3のoptionのidからstep3のid および　indexを取得して、storeにsetする
             */
            state.step3Id = step3Id
            let step3Index = dataStep3['items'].findIndex((element) => element.id === step3Id);
            state.step3_indexKey = step3Index

        },
        resetStep1(state) {
            state.step1Id = undefined
            state.step1_indexKey = undefined
            state.step1Img = imgNotSelected
        },
        resetStep2(state) {
            state.step2Id = undefined
            state.step2_indexKey = undefined
            state.step2Img = imgNotSelected
        },
        resetStep3(state) {
            state.step3Id = undefined
            state.step3_indexKey = undefined
            state.step3_option1_img = imgNotSelected
            state.step3_option2_img = imgNotSelected
        },
        resetAllItems(state) {
            state.step1Id = undefined
            state.step1_indexKey = undefined
            state.step1Img = imgNotSelected

            state.step2Id = undefined
            state.step2_indexKey = undefined
            state.step2Img = imgNotSelected

            state.step3Id = undefined
            state.step3_indexKey = undefined
            state.step3_option1_img = imgNotSelected
            state.step3_option2_img = imgNotSelected
        },
        activateNextBtnFunction(state) {
            state.activateNextBtn = true;
            switch (state.stepNumber) {
                case 1:
                    if (typeof state.step1Id == 'undefined') {
                        state.activateNextBtn = true;
                    } else {
                        state.activateNextBtn = false;
                    }
                    break
                case 2:
                    if (typeof state.step2Id == 'undefined') {
                        state.activateNextBtn = true;
                    } else {
                        state.activateNextBtn = false;
                    }
                    break
                case 3:
                    if (typeof state.step1Id == 'undefined' || typeof state.step2Id == 'undefined') {
                        state.activateNextBtn = true;
                    } else {
                        state.activateNextBtn = false;
                    }
                    break

            }
        },
        resetScrollPosition(state) {
            $(window).scrollTop(0)
        }
    },
    actions: {
        // 追加stepNavでページ移動
        clickedStepNavActions: function (context, {
            num
        }) {
            context.commit('clickedStepNav', num)
            context.commit('activateNextBtnFunction')
            context.commit('switchData')
            context.commit("resetScrollPosition")
        },
        clickedStepNext: function (context) {
            context.commit('nextStep')
            context.commit('activateNextBtnFunction')
            context.commit('switchData')
            context.commit("resetScrollPosition")
        },
        clickedStepBack: function (context) {
            context.commit('backStep')
            context.commit('switchData')
            context.commit('activateNextBtnFunction')
            context.commit("resetScrollPosition")
        },
        selectedScene: function (context, {
            scene
        }) {
            let selectedScene = scene;
            if (context.state.scene !== selectedScene) {


                context.commit('resetAllItems')
            }
            context.commit('setScene', selectedScene)
            context.commit('activateNextBtnFunction')
        },
        selectedItem: function (context, {
            itemId,
            itemImg
        }) {

            let item = {
                itemId: itemId,
                itemImg: itemImg,
            }

            if (context.state.stepNumber === 1) {
                if (context.state.step1Id === itemId) {
                    return;
                }
                context.commit('resetStep2')
                context.commit('resetStep3')
                context.commit('setItem1', item)
            }
            if (context.state.stepNumber === 2) {

                if (context.state.step2Id === itemId) {
                    return;
                }
                context.commit('resetStep3')
                context.commit('setItem2', item)

            }

            context.commit('activateNextBtnFunction')
        },

    }
}
export default storeCommon