"use strict";

import commonVariables from '../common/variables.js'
const imgUnnecessary = commonVariables.img_unnecessary;

const imgFolder = "assets/img/step/step2/";
let data = {
    option: true,
    cat: {
        bool: 0,
        value: ["test1", "test2"]
    },
    items: [
        {
            /**
             * -------------------------------------------------ホームベジトラグ ウォールハガーＳ
             */
            /**
             * starter
             */
            id: "p1_s",
            name: "ホームベジトラグ ウォールハガーＳ",
            img: imgFolder + "p1/01.jpg",
            description: "約幅103.5cm×奥行46cm×高さ80cm",
            description2: '組立品',
            cat: [0],
            option: "o1",
            slideItems: [{
                img: imgFolder + "p1/01.jpg",
                text: "ホームベジトラグ ウォールハガーＳ"
            },
                // {
                //     img: imgFolder + "p1/02.jpg",
                //     text: "step2bbb"
                // },
                // {
                //     img: imgFolder + "p1/03.jpg",
                //     text: "step2ccc"
                // },
            ],
        },
        {
            /**
             *repeater
             */
            id: "p1_r",
            name: "",
            img: imgUnnecessary,
            description: "「ホームベジトラグ ウォールハガーＳ」を既にお持ちの方",
            description2: '',
            cat: [0],
            option: "o1",
        },
        {
            /**
             * -------------------------------------------------"ハーブプランタースリム
             */
            /**
             * starter
             */
            id: "p2_s",
            name: "ハーブプランタースリム",
            img: imgFolder + "p2/01.jpg",
            description: "約幅77cm×奥行30cm×高さ80cm",
            description2: '組立品',
            cat: [0],
            option: "o2",
            slideItems: [{
                img: imgFolder + "p2/01.jpg",
                text: "ハーブプランタースリム"
            },
                // {
                //     img: imgFolder + "p2/02.jpg",
                //     text: "p2 02"
                // },
                // {
                //     img: imgFolder + "p2/03.jpg",
                //     text: "p2 03"
                // },
            ],
        },
        {
            /**
             *repeater
             */
            id: "p2_r",
            name: "",
            img: imgUnnecessary,
            description: "「ハーブプランタースリム」を既にお持ちの方",
            description2: '',
            cat: [0],
            option: "o2",
        }
    ]
}
export default data