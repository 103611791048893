
<template>
    <div id="stepNav" class="l_container_small" v-bind:class="{ 'useScene': useSceneClassActive }">
        <ol class="step_nav__progress">
            <li v-if="useSceneFlg" v-bind:class="{ 'current': nav0IsOn }" v-on:click="clicked(0)">
                <span class="num"></span>
                <span class="name">シーン</span>
            </li>
            <li v-bind:class="{ 'current': nav1IsOn }" v-on:click="clicked(1)">
                <span class="num"></span>
                <span class="name">苗</span>
            </li>
            <li v-bind:class="{ 'current': nav2IsOn }" v-on:click="clicked(2)">
                <span class="num"></span>
                <span class="name">プランター</span>
            </li>
            <li v-bind:class="{ 'current': nav3IsOn }" v-on:click="clicked(3)">
                <span class="num"></span>
                <span class="name">土/肥料</span>
            </li>
            <li v-bind:class="{ 'current': nav4IsOn }" v-on:click="clicked(4)">
                <span class="num"></span>
                <span class="name">ご確認</span>
            </li>
        </ol>
    </div>
</template>
  
<script>

export default {
    data() {
        return {
            nav0IsOn: false,
            nav1IsOn: false,
            nav2IsOn: false,
            nav3IsOn: false,
            nav4IsOn: false,
        }
    },
    computed: {
        // store
        appStepNum: function () { return this.$store.state.stepNumber },
        setAllItems: function () {
            return this.$store.getters.setAllItems
        },
        useSceneFlg: function () {
            return this.$store.state.useSceneFlg
        },
        // computed
        steptitle: function () {
            let text = "";
            switch (this.appStepNum) {
                case 1:
                    text = "まずは苗を選択してください。";
                    break;
                case 2:
                    text = "プランターの有無を選択してください。";
                    break;
                case 3:
                    text = "土の要不要を選択してください。";
                    break;
                case 4:
                    text = "確認画像";
                    break;
                default:
                    text = 'まずは苗を選択してください。'
            }
            return text;
        },
        useSceneClassActive: function () {
            if (this.useSceneFlg) {
                return true;
            }
            return false;
        }
    },
    watch: {
        appStepNum: function () {
            switch (this.appStepNum) {
                case 0:
                    this.nav0IsOn = true;
                    this.nav1IsOn = false;
                    this.nav2IsOn = false;
                    this.nav3IsOn = false;
                    this.nav4IsOn = false;
                    return;
                case 1:
                    this.nav0IsOn = false;
                    this.nav1IsOn = true;
                    this.nav2IsOn = false;
                    this.nav3IsOn = false;
                    this.nav4IsOn = false;
                    return;
                case 2:
                    this.nav0IsOn = false;
                    this.nav1IsOn = false;
                    this.nav2IsOn = true;
                    this.nav3IsOn = false;
                    this.nav4IsOn = false;
                    return;
                case 3:
                    this.nav0IsOn = false;
                    this.nav1IsOn = false;
                    this.nav2IsOn = false;
                    this.nav3IsOn = true;
                    this.nav4IsOn = false;
                    return;
                case 4:
                    this.nav0IsOn = false;
                    this.nav1IsOn = false;
                    this.nav2IsOn = false;
                    this.nav3IsOn = false;
                    this.nav4IsOn = true;
                    return;
                default:
                    this.nav1IsOn = true;
                    this.nav2IsOn = false;
                    this.nav3IsOn = false;
                    this.nav4IsOn = false;
                    return;
            }

        },
    },
    methods: {
        alert: function (message) {
            alert(message)
        },
        clicked: function (num) {
            let alertText, alertFlg = false;

            const stepNav = $("#stepNav")
            let getStepHtml = []
            let getStepHtmlNum
            let getStepHtmlName
            stepNav.find("li").each(function () {
                getStepHtmlNum = $(this).find(".num").text();
                getStepHtmlName = $(this).find(".name").text();
                getStepHtml.push({
                    'num': getStepHtmlNum,
                    'name': getStepHtmlName
                })
            })
            let text_step0_undefined;
            let text_step1_undefined;
            let text_step2_undefined;
            let text_step3_undefined;
            if (this.useSceneFlg) {
                text_step0_undefined = "先に" + getStepHtml[0].num + "の" + getStepHtml[0].name + "を選択して下さい。";
                text_step1_undefined = "先に" + getStepHtml[1].num + "の" + getStepHtml[1].name + "を選択して下さい。";
                text_step2_undefined = "先に" + getStepHtml[2].num + "の" + getStepHtml[2].name + "を選択して下さい。";
                text_step3_undefined = "先に" + getStepHtml[3].num + "の" + getStepHtml[3].name + "を選択して下さい。";
            } else {
                text_step1_undefined = "先に" + getStepHtml[0].num + "の" + getStepHtml[0].name + "を選択して下さい。";
                text_step2_undefined = "先に" + getStepHtml[1].num + "の" + getStepHtml[1].name + "を選択して下さい。";
                text_step3_undefined = "先に" + getStepHtml[2].num + "の" + getStepHtml[2].name + "を選択して下さい。";
            }

            switch (num) {
                case 1:
                    if (this.useSceneFlg) {
                        if (typeof this.setAllItems['scene'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step0_undefined;
                        }
                    }
                    break;
                case 2:
                    if (this.useSceneFlg) {
                        if (typeof this.setAllItems['scene'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step0_undefined;
                        }
                        else if (typeof this.setAllItems['step1_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step1_undefined;
                        }
                    }
                    else {
                        if (typeof this.setAllItems['step1_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step1_undefined;
                        }
                    }
                    break;
                case 3:
                    if (this.useSceneFlg) {
                        if (typeof this.setAllItems['scene'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step0_undefined;
                        }
                        else if (typeof this.setAllItems['step1_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step1_undefined;
                        }
                        else if (typeof this.setAllItems['step2_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step2_undefined;
                        }
                    } else {
                        if (typeof this.setAllItems['step1_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step1_undefined;
                        }
                        else if (typeof this.setAllItems['step2_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step2_undefined;
                        }
                    }

                    break;
                case 4:
                    if (this.useSceneFlg) {
                        if (typeof this.setAllItems['scene'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step0_undefined;
                        }
                        else if (typeof this.setAllItems['step1_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step1_undefined;
                        }
                        else if (typeof this.setAllItems['step2_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step2_undefined;
                        }
                        else if (typeof this.setAllItems['step3_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step3_undefined;
                        }
                    } else {
                        if (typeof this.setAllItems['step1_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step1_undefined;
                        }
                        else if (typeof this.setAllItems['step2_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step2_undefined;
                        }
                        else if (typeof this.setAllItems['step3_id'] === 'undefined') {
                            alertFlg = true;
                            alertText = text_step3_undefined;
                        }
                    }

                    break;
                default:
            }
            if (alertFlg) {
                this.alert(alertText)
            } else {
                this.$store.dispatch('clickedStepNavActions', { num })
            }

        }
    },
    created: function () {
        //step_nav__progressのliにcurrentをつけるかどうか
        if (this.useSceneFlg) {
            this.nav0IsOn = true;
            this.nav1IsOn = false;
            this.nav2IsOn = false;
            this.nav3IsOn = false;
            this.nav4IsOn = false;
        } else {
            this.nav0IsOn = false;
            this.nav1IsOn = true;
            this.nav2IsOn = false;
            this.nav3IsOn = false;
            this.nav4IsOn = false;
        }
    },
    mounted: function () {
        this.$nextTick(function () {
            const stepNavListItems = $("#stepNav").find(".step_nav__progress").find("li")
            let numHtml = 1;
            stepNavListItems.each(function () {
                $(this).find(".num").text("STEP." + numHtml)
                numHtml++;
            })
        });
    }
}
</script>