<template>
    <teleport to="#app">
        <div id="modal" v-if="modalFlg">

            <div id="modalContent" class="modal">
                <span id="modalClose" class="modal__close" @click="modalClose()"></span>
                <swiper :modules="modules" :slides-per-view="1" :space-between="0" navigation :pagination="{
                    type: 'fraction',
                }" @swiper="onSwiper" @slideChange="onSlideChange">
                    <swiper-slide v-for="(slide, index) in slideArray" :key="index">
                        <img v-bind:src="slide.img" alt="">
                        <p>{{slide.text}}</p>
                    </swiper-slide>
                </swiper>

            </div>
            <div id="modalOverlay" @click="modalClose()"></div>
        </div>
    </teleport>

</template>

<script>
import dataStep1 from './data/step1.js'
import dataStep2 from './data/step2.js'
import dataStep3 from './data/step3.js'
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


export default ({
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
        };
        const onSlideChange = () => {
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, A11y],
        };
    },
    data() {
        return {


        }
    },
    props: {
        modalFlg: Boolean,
        modalSetItem: Object
    },
    emits: ['modalClose'],
    computed: {
        slideArray: function () {
            let slideItems
            if (this.modalSetItem) {
                let step = this.modalSetItem.step
                let index = this.modalSetItem.index
                let itemInfo = this.modalSetItem.itemInfo
                let data

                if (step === 1) {
                    data = dataStep1.items
                }
                if (step === 2 ) {
                    data = dataStep2.items
                }
                if (step === 3 ) {
                    data = dataStep3.items
                }
                if (step === 1 || step === 2) {
                    slideItems = data[index].slideItems
                }
                if (step === 3) {
                    if (itemInfo === 'option1') {
                        slideItems = data[index].option.soil.slideItems
                    } else if (itemInfo === "option2") {
                        slideItems = data[index].option.fertilizer.slideItems
                    }

                }
                if (slideItems) {
                    slideItems = slideItems
                } else {
                    slideItems = []
                }
            } else {
                slideItems = []
            }


            return slideItems
        }
    },
    methods: {
        modalClose: function () {
            let flg = false;
            this.$emit('modalClose', flg)
        },
    }
})
</script>
