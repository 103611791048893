import dataStep1 from '../../data/step1.js'

import commonVariables from '../../common/variables.js'
const imgNotSelected = commonVariables.img_not_selected;
const stateCommon = {
    /**
     * 全ファイルで使用中
     */
    data: dataStep1,
    maxStepNumber: 4,

    scene: undefined,

    step1Id: undefined,
    step2Id: undefined,
    step3Id: undefined,

    step1_indexKey: undefined,
    step2_indexKey: undefined,
    step3_indexKey: undefined,

    /**
     *  app.vueで使用中
     */
    step1Img: imgNotSelected,
    step2Img: imgNotSelected,
    step3_option1_img: imgNotSelected,
    step3_option2_img: imgNotSelected,

    activateNextBtn: true,

    //step1に付随してるplanterIDを取得
    step2_planterId: [],
    //step2に付随してるoptionIDを取得
    step3_optionId: undefined,
}
export default stateCommon