//css
// import styles bundle
import '../scss/step.scss';

import {
    createApp
} from 'vue'

import App from './step_component/app.vue'
import storeCommon from './step_component/store/storeCommon'
import {
    createStore
} from 'vuex'

/**
 * set用
 */
import stateVal from './step_component/store/state/stateSet'
/**
 * set用 end
 */

// 新しいストアインスタンスを作成します
const store = createStore({
    state() {
        return stateVal
    },
    getters: storeCommon.getters,
    mutations: storeCommon.mutations,
    actions: storeCommon.actions,
})

const app = createApp(App)
app.use(store)

app.mount('#app')