"use strict";
const imgFolder = "assets/img/step/step3/";
let data = {
    option: true,
    cat: {
        bool: 1,
        value: ["test1", "test2"]
    },
    items: [{
        /**
         * -------------------------------------------------option_1
         * 土30ℓx4 * 肥料150gx1
         */
        id: "o1",
        name: "30ℓx4 * 150gx1",
        cat: [0],
        option: {
            'soil': {
                id: "o1_soil",
                name: "ベジトラグの土　30ℓx4",
                img: imgFolder + "soil/30l_4/01.jpg",
                slideItems: [{
                    img: imgFolder + "soil/30l_4/01.jpg",
                    text: "土01"
                },
                    // {
                    //     img: imgFolder + "soil/30l_4/02.jpg",
                    //     text: "土02"
                    // },
                    // {
                    //     img: imgFolder + "soil/30l_4/03.jpg",
                    //     text: "土03"
                    // },
                    // {
                    //     img: imgFolder + "soil/30l_4/04.jpg",
                    //     text: "土04"
                    // },
                ]
            },
            'fertilizer': {
                id: "o1_fertilizer",
                name: "肥料　150gx1",
                img: imgFolder + "fertilizer/150g_1/01.jpg",
                slideItems: [{
                    img: imgFolder + "fertilizer/150g_1/01.jpg",
                    text: "fertilizer01"
                },
                    // {
                    //     img: imgFolder + "fertilizer/150g_1/02.jpg",
                    //     text: "fertilizer02"
                    // },
                    // {
                    //     img: imgFolder + "fertilizer/150g_1/03.jpg",
                    //     text: "fertilizer03"
                    // },
                    // {
                    //     img: imgFolder + "fertilizer/150g_1/04.jpg",
                    //     text: "fertilizer04"
                    // },
                ]
            }
        }
    },
    {
        /**
         * -------------------------------------------------option_2
         * 土8ℓx2 * 肥料150gx1
         */
        id: "o2",
        name: "8ℓx2 * 150gx1",
        cat: [0],
        option: {
            'soil': {
                id: "o2_soil",
                name: "ベジトラグの土　8ℓx2",
                img: imgFolder + "soil/8l_2/01.jpg",
                slideItems: [{
                    img: imgFolder + "soil/8l_2/01.jpg",
                    text: "土01"
                },
                    // {
                    //     img: imgFolder + "soil/8l_2/02.jpg",
                    //     text: "土02"
                    // },
                    // {
                    //     img: imgFolder + "soil/8l_2/03.jpg",
                    //     text: "土03"
                    // },
                    // {
                    //     img: imgFolder + "soil/8l_2/04.jpg",
                    //     text: "土04"
                    // },
                ]
            },
            'fertilizer': {
                id: "o2_fertilizer",
                name: "肥料　150gx1",
                img: imgFolder + "fertilizer/150g_1/01.jpg",
                slideItems: [{
                    img: imgFolder + "fertilizer/150g_1/01.jpg",
                    text: "fertilizer01"
                },
                    // {
                    //     img: imgFolder + "fertilizer/150g_1/02.jpg",
                    //     text: "fertilizer02"
                    // },
                    // {
                    //     img: imgFolder + "fertilizer/150g_1/03.jpg",
                    //     text: "fertilizer03"
                    // },
                    // {
                    //     img: imgFolder + "fertilizer/150g_1/04.jpg",
                    //     text: "fertilizer04"
                    // },
                ]
            }
        }
    },

    ]
}
export default data