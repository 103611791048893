<template>
    <teleport to="#choiceNavArea">
        <div class="choiceNavArea">
            <p class="steptitle">ベジトラグを育てたい場所を選択してください。</p>

        </div>
    </teleport>
    <div id="selectScene">
        <div class="item-wrap">
            <div class="item" v-on:click="selectScene('sceneA')">
                <div class="img_area">
                    <img src="assets/img/step/step0/sceneA.jpg" alt="">
                </div>
                <p class="text_area">集合住宅</p>
                <div class="scene_cat_list_wrap">
                    <span class="scene_cat_list_head">おすすめ<span class="scene_cat_list_head_dot">：</span></span>
                    <ul class="scene_cat_list">
                        <li>花</li>
                        <li>野菜</li>
                        <li>ハーブ</li>
                    </ul>
                </div>
                <p class="text_description">本格的なガーデニングや家庭菜園が難しいアパート、マンションでのご利用に。入居者間のコミュニケーションの場としても最適です。</p>
            </div>
            <a class="case_btn" href="https://homeuse.takasho.co.jp/vegtrug/case/001/" target="_blank">事例を見る</a>
        </div>
        <!-- <div class="item" v-on:click="selectScene('sceneB')">
            <div class="img_area">
                <img src="assets/img/step/step0/sceneB.jpg" alt="">
            </div>
            <p class="text_area">地域教育施設</p>
        </div> -->
        <div class="item-wrap">
            <div class="item" v-on:click="selectScene('sceneC')">
                <div class="img_area">
                    <img src="assets/img/step/step0/sceneC.jpg" alt="">
                </div>
                <p class="text_area">レストラン・商業施設</p>
                <div class="scene_cat_list_wrap">
                    <span class="scene_cat_list_head">おすすめ<span class="scene_cat_list_head_dot">：</span></span>
                    <ul class="scene_cat_list">
                        <li>花</li>
                        <li>野菜</li>
                        <li>ハーブ</li>
                    </ul>
                </div>
                <p class="text_description">無農薬野菜の栽培や、お客様を迎える入り口に。安全な食の提供だけでなく、リラックスできる空間をつくります。</p>
            </div>
        </div>
        <!-- <div class="item" v-on:click="selectScene('sceneD')">
            <div class="img_area">
                <img src="assets/img/step/step0/sceneD.jpg" alt="">
            </div>
            <p class="text_area">貸農園</p>
        </div> -->
        <div class="item-wrap">
            <div class="item" v-on:click="selectScene('sceneE')">
                <div class="img_area">
                    <img src="assets/img/step/step0/sceneE.jpg" alt="">
                </div>
                <p class="text_area">オフィス</p>
                <div class="scene_cat_list_wrap">
                    <span class="scene_cat_list_head">おすすめ<span class="scene_cat_list_head_dot">：</span></span>
                    <ul class="scene_cat_list">
                        <li>花</li>
                        <li>ハーブ</li>
                    </ul>
                </div>
                <p class="text_description">ストレス緩和の効果がある自然の香りや色。成長の様子をきっかけに、コミュニケーションの活性化にもつながります。
                </p>
            </div>
        </div>
        <div class="item-wrap">
            <div class="item" v-on:click="selectScene('sceneF')">
                <div class="img_area">
                    <img src="assets/img/step/step0/sceneF.jpg" alt="">
                </div>
                <p class="text_area">保育・医療・福祉施設</p>
                <div class="scene_cat_list_wrap">
                    <span class="scene_cat_list_head">おすすめ<span class="scene_cat_list_head_dot">：</span></span>
                    <ul class="scene_cat_list">
                        <li>花</li>
                        <li>野菜</li>
                    </ul>
                </div>
                <p class="text_description">イスに座ったままや、立ったまま植物の世話ができるベジトラグ。身体への負担を減らし、植物の成長や収獲を楽しめます。</p>
            </div>
        </div>
    </div>
</template>

<script>


    export default {


        data() {
            return {
                currentItems: [],
            }
        },
        computed: {


        },
        watch: {

        },
        methods: {

            selectScene: function (scene) {
                this.$store.dispatch('selectedScene', { scene: scene })
            },

        },
    }
</script>