import {
    ref,
    computed
} from 'vue'

import {
    useStore
} from 'vuex'

export const useAltText = () => {

    const altText = (text) => {
        return text.replace('<br>', '');
    }
    return {
        altText
    }
}
export const useModal = () => {
    /**
     * vuexの値を参照
     */
    const store = useStore()
    const storeStepNumber = computed(() => store.state.stepNumber)
    const currentData = computed(() => store.state.data['items'])
    /**
     * refを設定
     */
    const modalFlgRef = ref(false)
    const modalSetItemRef = ref({})

    /**
     * methods modal.vueへmodalSetItemを渡す
     * (option適応の場合は、下のmodalOpen_optionを実行する)
     * @param {*} index 
     * @param {*} itemInfo 
     */
    const modalOpen = (itemInfo) => {

        let itemIndex;
        let step = storeStepNumber.value;
        let item_id = itemInfo

        /**
         * わたってきたアイテムのIDがをもとの配列の何番目か取得する
         */
        itemIndex = currentData.value.findIndex((element) => element.id === item_id);
        let modalItem = {
            step: step,
            index: itemIndex,
            itemInfo: item_id
        }
        modalFlgRef.value = true
        modalSetItemRef.value = modalItem
    }
    /**
     * methods step3の商品情報をmodal.vueへmodalSetItemを渡す
     * @param {*} index 
     * @param {*} itemInfo 
     */
    const modalOpen_option = (index, itemInfo) => {
        /**
         * modal.vueへmodalSetItemを渡す
         */
        let itemIndex = index;
        let step = storeStepNumber.value;
        let item_id = itemInfo

        let modalItem = {
            step: step,
            index: itemIndex,
            itemInfo: item_id
        }
        modalFlgRef.value = true
        modalSetItemRef.value = modalItem
    }
    const modalClose = (flg) => {
        /**
         * modal.vueからのmodal closeの受け取り
         * modal.vueへのpropsをリセット
         */
        if (!flg) {
            modalFlgRef.value = false
            modalSetItemRef.value = {}
        }
    }
    return {
        modalFlgRef,
        modalSetItemRef,
        modalOpen,
        modalOpen_option,
        modalClose
    }
}