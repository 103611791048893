"use strict";
const imgFolder = "assets/img/step/step1/";
let data = {
    option: false,
    cat: {
        bool: 1,
        value: ["花", "ハーブ"],
    },
    items: [
        /**
         * -------------------------------------------------n5
         */
        /**
         * -------------------------------------------------n6
         */
        // {
        //     id: "n6",
        //     name: "ソラマメ・ボリジ白 4ポット",
        //     img: imgFolder + "n6/01.jpg",
        //     img02: imgFolder + "n6/02.jpg",
        //     description: "季節の野菜苗を、野菜が元気に育つコンパニオンプランツと一緒にお届けします。			",
        //     price: 10000,
        //     scene: ["sceneF"],
        //     cat: [0],
        //     planter: ["p1_s", "p1_r"],
        //     slideItems: [
        //         {
        //             img: imgFolder + "n6/01.jpg",
        //             text: "",
        //         },
        //         {
        //             img: imgFolder + "n6/02.jpg",
        //             text: "",
        //         },
        //         {
        //             img: imgFolder + "n6/03.jpg",
        //             text: "",
        //         },
        //     ],
        // },
        //    /**
        //     * -------------------------------------------------n8
        //     */
        // {
        //     id: "n8",
        //     name: "リーフレタス・レモンタイム 4ポット",
        //     img: imgFolder + "n8/01.jpg",
        //     img02: imgFolder + "n8/02.jpg",
        //     description: "季節の野菜苗を、野菜が元気に育つコンパニオンプランツと一緒にお届けします。			",
        //     price: 10000,
        //     scene: ["sceneF"],
        //     cat: [0],
        //     planter: ["p1_s", "p1_r"],
        //     slideItems: [
        //         {
        //             img: imgFolder + "n8/01.jpg",
        //             text: "",
        //         },
        //         {
        //             img: imgFolder + "n8/02.jpg",
        //             text: "",
        //         },
        //         {
        //             img: imgFolder + "n8/03.jpg",
        //             text: "",
        //         },
        //     ],
        // },
        // {
        //     id: "n5",
        //     name: "白菜・チャイブ 4ポット",
        //     img: imgFolder + "n5/01.jpg",
        //     img02: imgFolder + "n5/02.jpg",
        //     description: "季節の野菜苗を、野菜が元気に育つコンパニオンプランツと一緒にお届けします。",
        //     price: 10000,
        //     scene: ["sceneA", "sceneC", "sceneE", "sceneF"],
        //     cat: [0],
        //     planter: ["p1_s", "p1_r"],
        //     slideItems: [
        //         {
        //             img: imgFolder + "n5/01.jpg",
        //             text: "",
        //         },
        //         {
        //             img: imgFolder + "n5/02.jpg",
        //             text: "",
        //         },
        //         {
        //             img: imgFolder + "n5/03.jpg",
        //             text: "",
        //         },
        //     ],
        // },
        /**
         * -------------------------------------------------n7
         */
        // {
        //     id: "n7",
        //     name: "スティックセニョール・ジャーマンカモミール 4ポット",
        //     img: imgFolder + "n7/01.jpg",
        //     img02: imgFolder + "n7/02.jpg",
        //     description: "季節の野菜苗を、野菜が元気に育つコンパニオンプランツと一緒にお届けします。			",
        //     price: 10000,
        //     scene: ["sceneF"],
        //     cat: [0],
        //     planter: ["p1_s", "p1_r"],
        //     slideItems: [
        //         {
        //             img: imgFolder + "n7/01.jpg",
        //             text: "",
        //         },
        //         {
        //             img: imgFolder + "n7/02.jpg",
        //             text: "",
        //         },
        //         {
        //             img: imgFolder + "n7/03.jpg",
        //             text: "",
        //         },
        //     ],
        // },
        /**
         * -------------------------------------------------n9
         */
        /**
         * -------------------------------------------------n1
         */
        //        {
        //            id: "n1",
        //            name: "春のお花アソート　16ポット",
        //            img: imgFolder + "n1/01.jpg",
        //            img02: imgFolder + "n1/02.jpg",
        //            description: "10種類の苗の中から選別してお送りいたします",
        //            price: 10000,
        //            scene: ["sceneA", "sceneE", "sceneF"],
        //            cat: [0],
        //            planter: ["p1_s", "p1_r"],
        //            slideItems: [{
        //                img: imgFolder + "n1/01.jpg",
        //                text: ""
        //            },
        //            {
        //                img: imgFolder + "n1/02.jpg",
        //                text: ""
        //            },
        //            {
        //                img: imgFolder + "n1/03.jpg",
        //                text: ""
        //            },
        //            {
        //                img: imgFolder + "n1/04.jpg",
        //                text: ""
        //            },
        //            {
        //                img: imgFolder + "n1/05.jpg",
        //                text: ""
        //            },
        //            ]
        //        },
        /**
         * -------------------------------------------------n2
         */
        // {
        //     id: "n2",
        //     name: "夏のお花アソート 16ポット",
        //     img: imgFolder + "n2/01.jpg",
        //     img02: imgFolder + "n2/02.jpg",
        //     description: "高低差や色合いを考慮した季節のおすすめ品種をお届けします。",
        //     price: 10000,
        //     scene: ["sceneA", "sceneC", "sceneE", "sceneF"],
        //     cat: [0],
        //     planter: ["p1_s", "p1_r"],
        //     slideItems: [{
        //         img: imgFolder + "n2/01.jpg",
        //         text: ""
        //     },
        //     {
        //         img: imgFolder + "n2/02.jpg",
        //         text: ""
        //     },
        //     {
        //         img: imgFolder + "n2/03.jpg",
        //         text: ""
        //     },
        //     {
        //         img: imgFolder + "n2/04.jpg",
        //         text: ""
        //     },
        //     {
        //         img: imgFolder + "n2/05.jpg",
        //         text: ""
        //     },
        //     {
        //         img: imgFolder + "n2/06.jpg",
        //         text: ""
        //     },
        //     ]
        // },
        /**
         * -------------------------------------------------n3
         */
//        {
//            id: "n3",
//            name: "秋のお花アソート　16ポット",
//            img: imgFolder + "n3/01.jpg",
//            img02: imgFolder + "n3/02.jpg",
//            description: "高低差や色合いを考慮した季節のおすすめ品種をお届けします。",
//            price: 10000,
//            scene: ["sceneA", "sceneC", "sceneE", "sceneF"],
//            cat: [0],
//            planter: ["p1_s", "p1_r"],
//            slideItems: [
//                {
//                    img: imgFolder + "n3/01.jpg",
//                    text: "",
//                },
//                {
//                    img: imgFolder + "n3/02.jpg",
//                    text: "",
//                },
//                {
//                    img: imgFolder + "n3/03.jpg",
//                    text: "",
//                },
//                {
//                    img: imgFolder + "n3/04.jpg",
//                    text: "",
//                },
//                {
//                    img: imgFolder + "n3/05.jpg",
//                    text: "",
//                },
//            ],
//        },
        /**
         * -------------------------------------------------n4
         */
         {
             id: "n4",
             name: "冬のお花アソート　16ポット",
             img: imgFolder + "n4/01.png",
             img02: imgFolder + "n4/02.png",
             description: "高低差や色合いを考慮した季節のおすすめ品種をお届けします。",
             price: 10000,
             scene: ["sceneA", "sceneE", "sceneF"],
             cat: [0],
             planter: ["p1_s", "p1_r"],
             slideItems: [{
                 img: imgFolder + "n4/01.png",
                 text: ""
             },
             {
                 img: imgFolder + "n4/02.png",
                 text: ""
             },
             {
                 img: imgFolder + "n4/03.png",
                 text: ""
             },
             {
                 img: imgFolder + "n4/04.png",
                 text: ""
             },
             {
                 img: imgFolder + "n4/05.jpg?20241203",
                 text: ""
             },
             ]
         },

        {
            id: "n9",
            name: "ハーブティーにおすすめハーブ 4ポット",
            img: imgFolder + "n9/01.jpg",
            img02: imgFolder + "n9/02.jpg",
            description: "ハーブティにおすすめ品種、全17種のうち４種類をお届けします。",
            price: 10000,
            scene: ["sceneA", "sceneC", "sceneE"],
            cat: [1],
            planter: ["p2_s", "p2_r"],
            slideItems: [
                {
                    img: imgFolder + "n9/01.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n9/02.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n9/03.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n9/04.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n9/05.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n9/06.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n9/07.jpg",
                    text: "",
                },
            ],
        },
        {
            /**
             * -------------------------------------------------n10
             */
            id: "n10",
            name: "料理におすすめハーブ 4ポット",
            img: imgFolder + "n10/01.jpg",
            img02: imgFolder + "n10/02.jpg",
            description: "ハーブ料理におすすめ品種、全25種のうち４種類をお届けします。",
            price: 10000,
            scene: ["sceneA", "sceneC", "sceneE"],
            cat: [1],
            planter: ["p2_s", "p2_r"],
            slideItems: [
                {
                    img: imgFolder + "n10/01.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n10/02.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n10/03.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n10/04.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n10/05.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n10/06.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n10/07.jpg",
                    text: "",
                },
            ],
        },
        {
            /**
             * -------------------------------------------------n11
             */
            id: "n11",
            name: "生活に活用ハーブ 4ポット",
            img: imgFolder + "n11/01.jpg",
            img02: imgFolder + "n11/02.jpg",
            description: "生活に活用できるハーブ品種、全13種のうち４種類をお届けします。",
            price: 10000,
            scene: ["sceneA", "sceneC", "sceneE"],
            cat: [1],
            planter: ["p2_s", "p2_r"],
            slideItems: [
                {
                    img: imgFolder + "n11/01.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n11/02.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n11/03.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n11/04.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n11/05.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n11/06.jpg",
                    text: "",
                },
                {
                    img: imgFolder + "n11/07.jpg",
                    text: "",
                },
            ],
        },
    ],
};
export default data;
